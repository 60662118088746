let commonMap: Map<string, string> = new Map();
let unitsMap: Map<string, string> = new Map();
let traitsMap: Map<string, string> = new Map();
let itemsMap: Map<string, string> = new Map([
  ["NeekosHelp", "Champion Duplicator"],
  ["ChampionDuplicator", "Champion Duplicator"],
  ["ShopReroller", "Loaded Dice"],
  ["ItemReroller", "Reforger"],
  ["ItemRemover", "Magnetic Remover"],
  ["ChampionDuplicator_III", "Lesser Champion Duplicator"],
]);
let augmentsMap: Map<string, string> = new Map();

export function init(
  commonData: { [key: string]: string },
  unitsData: { [key: string]: string },
  traitsData: { [key: string]: string },
  itemsData: { [key: string]: string },
  augmentsData: { [key: string]: string },
) {
  for (let k in unitsData) {
    unitsMap.set(k, unitsData[k]);
  }
  for (let k in traitsData) {
    traitsMap.set(k, traitsData[k]);
  }
  for (let k in itemsData) {
    itemsMap.set(k, itemsData[k]);
  }
  for (let k in augmentsData) {
    augmentsMap.set(k, augmentsData[k]);
  }
  for (let k in commonData) {
    commonMap.set(k, commonData[k]);
  }
}

export const common = (key: string) => commonMap.get(key) || key;
export const units = (key: string) => unitsMap.get(key) || key;
export const traits = (key: string) => traitsMap.get(key) || key;
export const items = (key: string) => itemsMap.get(key) || key;
export const augments = (key: string) => augmentsMap.get(key) || key;

// const datatmp: any = require("../data/Datatmp.json");

// const tmpUnits: any = {};
// const tmpTraits: any = {};
// const tmpItems: any = {};
// const tmpAugs: any = {};

// for (let unitId in datatmp.units) {
//   const unit = datatmp.units[unitId];
//   tmpUnits[unitId] = unit.name;
//   tmpUnits[unitId + "_ability"] = unit.ability.name;
//   tmpUnits[unitId + "_desc"] = unit.ability.desc;
//   unit.ability.botUi.forEach((deet: any, idx: any) => {
//     tmpUnits[`${unitId}_details_${idx}`] = deet;
//   });
// }
// for (let traitId in datatmp.traits) {
//   tmpTraits[traitId] = datatmp.traits[traitId].name;
//   tmpTraits[traitId + "_desc"] = datatmp.traits[traitId].desc;
// }

// for (let itemId in datatmp.items) {
//   tmpItems[itemId] = datatmp.items[itemId].name;
//   tmpItems[itemId + "_desc"] = datatmp.items[itemId].desc;
// }

// init(
//   // @ts-ignore
//   {},
//   // @ts-ignore
//   tmpUnits,
//   // @ts-ignore
//   tmpTraits,
//   // @ts-ignore
//   tmpItems,
//   // @ts-ignore
//   tmpAugs,
// );

if (typeof window !== "undefined") {
  init(
    // @ts-ignore
    window.s9SetInfoi18n,
    // @ts-ignore
    window.s9Unitsi18n,
    // @ts-ignore
    window.s9Traitsi18n,
    // @ts-ignore
    window.s9Itemsi18n,
    // @ts-ignore
    window.s9Augmentsi18n,
  );

  init(
    // @ts-ignore
    window.s95SetInfoi18n,
    // @ts-ignore
    window.s95Unitsi18n,
    // @ts-ignore
    window.s95Traitsi18n,
    // @ts-ignore
    window.s95Itemsi18n,
    // @ts-ignore
    window.s95Augmentsi18n,
  );

  init(
    // @ts-ignore
    window.s36SetInfoi18n,
    // @ts-ignore
    window.s36Unitsi18n,
    // @ts-ignore
    window.s36Traitsi18n,
    // @ts-ignore
    window.s36Itemsi18n,
    // @ts-ignore
    window.s36Augmentsi18n,
  );

  init(
    // @ts-ignore
    window.s56SetInfoi18n,
    // @ts-ignore
    window.s56Unitsi18n,
    // @ts-ignore
    window.s56Traitsi18n,
    // @ts-ignore
    window.s56Itemsi18n,
    // @ts-ignore
    window.s56Augmentsi18n,
  );

  init(
    // @ts-ignore
    window.s10SetInfoi18n,
    // @ts-ignore
    window.s10Unitsi18n,
    // @ts-ignore
    window.s10Traitsi18n,
    // @ts-ignore
    window.s10Itemsi18n,
    // @ts-ignore
    window.s10Augmentsi18n,
  );

  init(
    // @ts-ignore
    window.s11SetInfoi18n,
    // @ts-ignore
    window.s11Unitsi18n,
    // @ts-ignore
    window.s11Traitsi18n,
    // @ts-ignore
    window.s11Itemsi18n,
    // @ts-ignore
    window.s11Augmentsi18n,
  );

  init(
    // @ts-ignore
    window.s12SetInfoi18n,
    // @ts-ignore
    window.s12Unitsi18n,
    // @ts-ignore
    window.s12Traitsi18n,
    // @ts-ignore
    window.s12Itemsi18n,
    // @ts-ignore
    window.s12Augmentsi18n,
  );

  init(
    // @ts-ignore
    window.s46SetInfoi18n,
    // @ts-ignore
    window.s46Unitsi18n,
    // @ts-ignore
    window.s46Traitsi18n,
    // @ts-ignore
    window.s46Itemsi18n,
    // @ts-ignore
    window.s46Augmentsi18n,
  );

  init(
    // @ts-ignore
    window.s13SetInfoi18n,
    // @ts-ignore
    window.s13Unitsi18n,
    // @ts-ignore
    window.s13Traitsi18n,
    // @ts-ignore
    window.s13Itemsi18n,
    // @ts-ignore
    window.s13Augmentsi18n,
  );

  init(
    // @ts-ignore
    window.s14SetInfoi18n,
    // @ts-ignore
    window.s14Unitsi18n,
    // @ts-ignore
    window.s14Traitsi18n,
    // @ts-ignore
    window.s14Itemsi18n,
    // @ts-ignore
    window.s14Augmentsi18n,
  );
}
