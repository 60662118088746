let data14;
if (typeof window === "undefined") {
  data14 = require("../static-s14/Data.json");
} else {
  data14 = require("../static-s14/Data.json");
  window.data14 = data14;
  // data14 = window.data14;
}

export { data14 };
