// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Sd from "../tft-static/Sd.bs.js";
import * as Data9 from "./Data9.bs.js";
import * as Fnv1a from "../bindings/Fnv1a.bs.js";
import * as Data10 from "./Data10.bs.js";
import * as Data11 from "./Data11.bs.js";
import * as Data12 from "./Data12.bs.js";
import * as Data13 from "./Data13.bs.js";
import * as Data14 from "./Data14.bs.js";
import * as Data36 from "./Data36.bs.js";
import * as Data46 from "./Data46.bs.js";
import * as Data56 from "./Data56.bs.js";
import * as Data95 from "./Data95.bs.js";
import * as Global from "../Global.bs.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DataBindJs from "./Data.bind.js";

function encodeItemId(prim) {
  return DataBindJs.encodeItemId(prim);
}

function encodeAugmentId(prim) {
  return DataBindJs.encodeAugmentId(prim);
}

function fixId(unitId) {
  return Sd.delowerId(unitId);
}

function units(set) {
  if (set >= 90) {
    if (set < 121) {
      if (set > 119 || set < 101) {
        if (set >= 120) {
          return Data12.units;
        }
        switch (set) {
          case 90 :
              return Data9.units;
          case 95 :
              return Data95.units;
          case 91 :
          case 92 :
          case 93 :
          case 94 :
          case 96 :
          case 97 :
          case 98 :
          case 99 :
              break;
          case 100 :
              return Data10.units;
          
        }
      } else if (set === 110) {
        return Data11.units;
      }
      
    } else {
      if (set === 130) {
        return Data13.units;
      }
      if (set === 140) {
        return Data14.units;
      }
      
    }
  } else if (set >= 37) {
    if (set === 46) {
      return Data46.units;
    }
    if (set === 56) {
      return Data56.units;
    }
    
  } else {
    if (set === 10) {
      return Data10.units;
    }
    if (set >= 36) {
      return Data36.units;
    }
    
  }
  return Js_exn.raiseError("Invalid set " + String(set));
}

function traits(set) {
  if (set >= 90) {
    if (set < 121) {
      if (set > 119 || set < 101) {
        if (set >= 120) {
          return Data12.traits;
        }
        switch (set) {
          case 90 :
              return Data9.traits;
          case 95 :
              return Data95.traits;
          case 91 :
          case 92 :
          case 93 :
          case 94 :
          case 96 :
          case 97 :
          case 98 :
          case 99 :
              break;
          case 100 :
              return Data10.traits;
          
        }
      } else if (set === 110) {
        return Data11.traits;
      }
      
    } else {
      if (set === 130) {
        return Data13.traits;
      }
      if (set === 140) {
        return Data14.traits;
      }
      
    }
  } else if (set >= 37) {
    if (set === 46) {
      return Data46.traits;
    }
    if (set === 56) {
      return Data56.traits;
    }
    
  } else {
    if (set === 10) {
      return Data10.traits;
    }
    if (set >= 36) {
      return Data36.traits;
    }
    
  }
  return Js_exn.raiseError("Invalid set " + String(set));
}

function itemId(rawId) {
  return rawId.replace(/TFT(.*?)_Item_(.+)/, "$1$2");
}

function itemInfo(id, set) {
  if (set >= 90) {
    if (set < 121) {
      if (set > 119 || set < 101) {
        if (set >= 120) {
          return Js_dict.get(Data12.items, id);
        }
        switch (set) {
          case 90 :
              return Js_dict.get(Data9.items, id);
          case 95 :
              var id$1 = id === "SeraphsEmbrace" ? "BlueBuff" : id;
              return Js_dict.get(Data95.items, id$1);
          case 91 :
          case 92 :
          case 93 :
          case 94 :
          case 96 :
          case 97 :
          case 98 :
          case 99 :
              break;
          case 100 :
              return Js_dict.get(Data10.items, id);
          
        }
      } else if (set === 110) {
        return Js_dict.get(Data11.items, id);
      }
      
    } else {
      if (set === 130) {
        return Js_dict.get(Data13.items, id);
      }
      if (set === 140) {
        return Js_dict.get(Data14.items, id);
      }
      
    }
  } else if (set >= 37) {
    if (set === 46) {
      return Js_dict.get(Data46.items, id);
    }
    if (set === 56) {
      return Js_dict.get(Data56.items, id);
    }
    
  } else {
    if (set === 10) {
      return Js_dict.get(Data10.items, id);
    }
    if (set >= 36) {
      return Js_dict.get(Data36.items, id);
    }
    
  }
  return Js_exn.raiseError("Invalid set " + String(set));
}

function itemInfoExn(id, set) {
  return Belt_Option.getExn(itemInfo(id, set));
}

function items(set) {
  if (set >= 90) {
    if (set < 121) {
      if (set > 119 || set < 101) {
        if (set >= 120) {
          return Data12.items;
        }
        switch (set) {
          case 90 :
              return Data9.items;
          case 95 :
              return Data95.items;
          case 91 :
          case 92 :
          case 93 :
          case 94 :
          case 96 :
          case 97 :
          case 98 :
          case 99 :
              break;
          case 100 :
              return Data10.items;
          
        }
      } else if (set === 110) {
        return Data11.items;
      }
      
    } else {
      if (set === 130) {
        return Data13.items;
      }
      if (set === 140) {
        return Data14.items;
      }
      
    }
  } else if (set >= 37) {
    if (set === 46) {
      return Data46.items;
    }
    if (set === 56) {
      return Data56.items;
    }
    
  } else {
    if (set === 10) {
      return Data10.items;
    }
    if (set >= 36) {
      return Data36.items;
    }
    
  }
  return Js_exn.raiseError("Invalid set " + String(set));
}

function itemsArr(set) {
  if (set >= 90) {
    if (set < 121) {
      if (set > 119 || set < 101) {
        if (set >= 120) {
          return Data12.itemsArr();
        }
        switch (set) {
          case 90 :
              return Data9.itemsArr();
          case 95 :
              return Data95.itemsArr();
          case 91 :
          case 92 :
          case 93 :
          case 94 :
          case 96 :
          case 97 :
          case 98 :
          case 99 :
              break;
          case 100 :
              return Data10.itemsArr();
          
        }
      } else if (set === 110) {
        return Data11.itemsArr();
      }
      
    } else {
      if (set === 130) {
        return Data13.itemsArr();
      }
      if (set === 140) {
        return Data14.itemsArr();
      }
      
    }
  } else if (set >= 37) {
    if (set === 46) {
      return Data46.itemsArr();
    }
    if (set === 56) {
      return Data56.itemsArr();
    }
    
  } else {
    if (set === 10) {
      return Data10.itemsArr();
    }
    if (set >= 36) {
      return Data36.itemsArr();
    }
    
  }
  return Js_exn.raiseError("Invalid set " + String(set));
}

function augments(set) {
  if (set >= 90) {
    if (set < 121) {
      if (set > 119 || set < 101) {
        if (set >= 120) {
          return Data12.augments;
        }
        switch (set) {
          case 90 :
              return Data9.augments;
          case 95 :
              return Data95.augments;
          case 91 :
          case 92 :
          case 93 :
          case 94 :
          case 96 :
          case 97 :
          case 98 :
          case 99 :
              break;
          case 100 :
              return Data10.augments;
          
        }
      } else if (set === 110) {
        return Data11.augments;
      }
      
    } else {
      if (set === 130) {
        return Data13.augments;
      }
      if (set === 140) {
        return Data14.augments;
      }
      
    }
  } else if (set >= 37) {
    if (set === 46) {
      return Data46.augments;
    }
    if (set === 56) {
      return Data56.augments;
    }
    
  } else {
    if (set === 10) {
      return Data10.augments;
    }
    if (set >= 36) {
      return Data36.augments;
    }
    
  }
  return Js_exn.raiseError("Invalid set " + String(set));
}

function augmentId(rawId) {
  return rawId.replace(/TFT(.*?)_Augment_(.+)/, "$1$2");
}

function augmentInfo(id, set) {
  var id$1 = DataBindJs.encodeAugmentId(id);
  if (set >= 90) {
    if (set < 121) {
      if (set > 119 || set < 101) {
        if (set >= 120) {
          return Js_dict.get(Data12.augments, DataBindJs.encodeAugmentId(id$1));
        }
        switch (set) {
          case 90 :
              var v = Js_dict.get(Data9.augments, DataBindJs.encodeAugmentId(id$1));
              if (v !== undefined) {
                return v;
              } else {
                return Js_dict.get(Data9.legendAugments, DataBindJs.encodeAugmentId(id$1));
              }
          case 95 :
              return Js_dict.get(Data95.augments, DataBindJs.encodeAugmentId(id$1));
          case 91 :
          case 92 :
          case 93 :
          case 94 :
          case 96 :
          case 97 :
          case 98 :
          case 99 :
              break;
          case 100 :
              return Js_dict.get(Data10.augments, DataBindJs.encodeAugmentId(id$1));
          
        }
      } else if (set === 110) {
        return Js_dict.get(Data11.augments, DataBindJs.encodeAugmentId(id$1));
      }
      
    } else {
      if (set === 130) {
        return Js_dict.get(Data13.augments, DataBindJs.encodeAugmentId(id$1));
      }
      if (set === 140) {
        return Js_dict.get(Data14.augments, DataBindJs.encodeAugmentId(id$1));
      }
      
    }
  } else if (set >= 37) {
    if (set === 46) {
      return Js_dict.get(Data46.augments, DataBindJs.encodeAugmentId(id$1));
    }
    if (set === 56) {
      return Js_dict.get(Data56.augments, DataBindJs.encodeAugmentId(id$1));
    }
    
  } else {
    if (set === 10) {
      return Js_dict.get(Data10.augments, DataBindJs.encodeAugmentId(id$1));
    }
    if (set >= 36) {
      return Js_dict.get(Data36.augments, DataBindJs.encodeAugmentId(id$1));
    }
    
  }
  return Js_exn.raiseError("Invalid set " + String(set));
}

function augmentInfoExn(id, set) {
  return Belt_Option.getExn(augmentInfo(id, set));
}

function fixVarName(varName) {
  if (varName === "Exp") {
    return "EXP";
  } else {
    return varName;
  }
}

function replaceAugmentVariables(effects, desc, id, set) {
  var effects$1 = effects !== undefined ? Caml_option.valFromOption(effects) : augmentInfoExn(id, set).effects;
  var parts = Belt_Array.map(desc.split(new RegExp("(@.+?@)", "gm")), Belt_Option.getExn);
  return Belt_Array.map(parts, (function (part) {
                      if (part.charAt(0) !== "@") {
                        return part;
                      }
                      var p = part.slice(1, part.length - 1 | 0);
                      var adjP = Belt_Array.getExn(p.split("*"), 0);
                      var f = Js_dict.get(effects$1, fixVarName(adjP));
                      var v = f !== undefined ? f : Js_dict.get(effects$1, "{" + Fnv1a.ritoHash(fixVarName(adjP)) + "}");
                      if (v == null) {
                        return "0";
                      } else if (part.includes("*100")) {
                        return Global.formatPctSmart(v);
                      } else {
                        return Global.toFixedSmart(v);
                      }
                    })).join("").replace("%%", "%").replace("%%", "%");
}

var ornnItems = [
  "4OrnnAnimaVisage",
  "4OrnnDeathsDefiance",
  "4OrnnEternalWinter",
  "4OrnnInfinityForce",
  "4OrnnMuramana",
  "4OrnnObsidianCleaver",
  "4OrnnRanduinsSanctum",
  "4OrnnRocketPropelledFist",
  "4OrnnTheCollector",
  "4OrnnZhonyasParadox"
];

export {
  encodeItemId ,
  encodeAugmentId ,
  fixId ,
  units ,
  traits ,
  itemId ,
  itemInfo ,
  itemInfoExn ,
  items ,
  itemsArr ,
  augments ,
  augmentId ,
  augmentInfo ,
  augmentInfoExn ,
  fixVarName ,
  replaceAugmentVariables ,
  ornnItems ,
  
}
/* Sd Not a pure module */
