// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Config from "./Config.bs.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function patch(p) {
  return /* Patch */{
          _0: p
        };
}

function renderCore(season, patch) {
  if (season !== 14) {
    if (season === 15) {
      if (patch >= 7) {
        return "14." + String(patch - 6 | 0);
      } else {
        return "13." + String(patch + 2 | 0);
      }
    }
    
  } else {
    if (patch === 23) {
      return "13.1";
    }
    if (patch === 24) {
      return "13.2";
    }
    
  }
  return String(season) + "." + String(patch);
}

function render(patch) {
  if (patch > 10000) {
    var season = patch / 1000 | 0;
    var patchBase = patch % 1000 / 10 | 0;
    var micropatch = patch % 10;
    var tmp;
    switch (micropatch) {
      case 1 :
          tmp = "b";
          break;
      case 2 :
          tmp = "c";
          break;
      case 3 :
          tmp = "d";
          break;
      default:
        tmp = "";
    }
    return renderCore(season, patchBase) + tmp;
  }
  var season$1 = patch / 100 | 0;
  var patch_base = patch % 100;
  return String(season$1) + "." + (
          (patch_base / 2 | 0) < 10 ? "0" : ""
        ) + String(patch_base / 2 | 0) + (
          patch_base % 2 === 1 ? "b" : ""
        );
}

function renderOld(patch) {
  if (patch > 10000) {
    var season = patch / 1000 | 0;
    var patchBase = patch % 1000 / 10 | 0;
    var micropatch = patch % 10;
    var tmp;
    switch (micropatch) {
      case 1 :
          tmp = "b";
          break;
      case 2 :
          tmp = "c";
          break;
      case 3 :
          tmp = "d";
          break;
      default:
        tmp = "";
    }
    return String(season) + "." + String(patchBase) + tmp;
  }
  var season$1 = patch / 100 | 0;
  var patch_base = patch % 100;
  return String(season$1) + "." + (
          (patch_base / 2 | 0) < 10 ? "0" : ""
        ) + String(patch_base / 2 | 0) + (
          patch_base % 2 === 1 ? "b" : ""
        );
}

function make(patch) {
  return /* Patch */{
          _0: patch
        };
}

function renderFull(patch) {
  if (patch) {
    return render(patch._0);
  } else {
    return "latest";
  }
}

function renderBase(patch) {
  if (patch > 10000) {
    var season = patch / 1000 | 0;
    var patch_base = patch % 1000 / 10 | 0;
    return renderCore(season, patch_base);
  }
  var season$1 = patch / 100 | 0;
  var patch_base$1 = patch % 100;
  return String(season$1) + "." + String(patch_base$1 / 2 | 0);
}

function fromUrl(patch) {
  if (patch === "latest") {
    return /* Latest */0;
  }
  var parts = patch.split(".");
  if (parts.length !== 2) {
    return ;
  }
  var season = parts[0];
  var patch$1 = parts[1];
  try {
    var season$1 = Belt_Option.getExn(Belt_Int.fromString(season));
    var patchNum = Belt_Option.getExn(Belt_Int.fromString(patch$1.slice(0, 2)));
    var micropatch = patch$1[2];
    if (season$1 > 12 || season$1 === 12 && patchNum > 12 || season$1 === 12 && patchNum === 12 && Caml_obj.caml_equal(micropatch, "c")) {
      var patch$2;
      if (season$1 === 14) {
        var tmp;
        if (micropatch !== undefined) {
          switch (micropatch) {
            case "b" :
                tmp = 1;
                break;
            case "c" :
                tmp = 2;
                break;
            case "d" :
                tmp = 3;
                break;
            default:
              tmp = 0;
          }
        } else {
          tmp = 0;
        }
        patch$2 = (15000 + Math.imul(patchNum + 6 | 0, 10) | 0) + tmp | 0;
      } else if (season$1 === 13) {
        if (patchNum < 3) {
          var tmp$1;
          if (micropatch !== undefined) {
            switch (micropatch) {
              case "b" :
                  tmp$1 = 1;
                  break;
              case "c" :
                  tmp$1 = 2;
                  break;
              case "d" :
                  tmp$1 = 3;
                  break;
              default:
                tmp$1 = 0;
            }
          } else {
            tmp$1 = 0;
          }
          patch$2 = (14000 + Math.imul(patchNum + 22 | 0, 10) | 0) + tmp$1 | 0;
        } else {
          var tmp$2;
          if (micropatch !== undefined) {
            switch (micropatch) {
              case "b" :
                  tmp$2 = 1;
                  break;
              case "c" :
                  tmp$2 = 2;
                  break;
              case "d" :
                  tmp$2 = 3;
                  break;
              default:
                tmp$2 = 0;
            }
          } else {
            tmp$2 = 0;
          }
          patch$2 = (15000 + Math.imul(patchNum - 2 | 0, 10) | 0) + tmp$2 | 0;
        }
      } else {
        var tmp$3;
        if (micropatch !== undefined) {
          switch (micropatch) {
            case "b" :
                tmp$3 = 1;
                break;
            case "c" :
                tmp$3 = 2;
                break;
            case "d" :
                tmp$3 = 3;
                break;
            default:
              tmp$3 = 0;
          }
        } else {
          tmp$3 = 0;
        }
        patch$2 = (Math.imul(season$1, 1000) + Math.imul(patchNum, 10) | 0) + tmp$3 | 0;
      }
      if (Config.allPatches.includes(patch$2)) {
        return /* Patch */{
                _0: patch$2
              };
      } else {
        return ;
      }
    }
    var bPatch = Caml_obj.caml_equal(micropatch, "b");
    var patch$3 = (Math.imul(season$1, 100) + (patchNum << 1) | 0) + (
      bPatch ? 1 : 0
    ) | 0;
    if (Config.allPatches.includes(patch$3)) {
      return /* Patch */{
              _0: (Math.imul(season$1, 100) + (patchNum << 1) | 0) + (
                bPatch ? 1 : 0
              ) | 0
            };
    } else {
      return ;
    }
  }
  catch (exn){
    return ;
  }
}

function toApiUrl(patch) {
  if (patch) {
    return String(patch._0);
  } else {
    return "latest";
  }
}

function fromApiUrl(patch) {
  if (patch === "latest") {
    return /* Latest */0;
  } else {
    return /* Patch */{
            _0: Belt_Option.getExn(Belt_Int.fromString(patch))
          };
  }
}

var latest = /* Latest */0;

export {
  latest ,
  patch ,
  renderCore ,
  render ,
  renderOld ,
  make ,
  renderFull ,
  renderBase ,
  fromUrl ,
  toApiUrl ,
  fromApiUrl ,
  
}
/* Config Not a pure module */
