// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

var ctx = React.createContext(/* Set11 */3);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = ctx.Provider;

var make$1 = React.memo(make);

var Provider = {
  makeProps: makeProps,
  make: make$1
};

function num(set) {
  switch (set) {
    case /* Set9 */0 :
        return 90;
    case /* Set95 */1 :
        return 95;
    case /* Set10 */2 :
        return 10;
    case /* Set11 */3 :
        return 110;
    case /* Set12 */4 :
        return 120;
    case /* Set13 */5 :
        return 130;
    case /* Set14 */6 :
        return 140;
    case /* Set36 */7 :
        return 36;
    case /* Set56 */8 :
        return 56;
    case /* Set46 */9 :
        return 46;
    
  }
}

function num2(set) {
  switch (set) {
    case /* Set9 */0 :
        return 90;
    case /* Set95 */1 :
        return 95;
    case /* Set10 */2 :
        return 100;
    case /* Set11 */3 :
        return 110;
    case /* Set12 */4 :
        return 120;
    case /* Set13 */5 :
        return 130;
    case /* Set14 */6 :
        return 140;
    case /* Set36 */7 :
        return 36;
    case /* Set56 */8 :
        return 56;
    case /* Set46 */9 :
        return 46;
    
  }
}

function fromNum(num) {
  if (num < 90) {
    if (num >= 37) {
      if (num !== 46) {
        if (num !== 56) {
          return /* Set11 */3;
        } else {
          return /* Set56 */8;
        }
      } else {
        return /* Set46 */9;
      }
    } else if (num !== 10) {
      if (num >= 36) {
        return /* Set36 */7;
      } else {
        return /* Set11 */3;
      }
    } else {
      return /* Set10 */2;
    }
  }
  if (num >= 121) {
    if (num !== 130) {
      if (num !== 140) {
        return /* Set11 */3;
      } else {
        return /* Set14 */6;
      }
    } else {
      return /* Set13 */5;
    }
  }
  if (!(num > 119 || num < 101)) {
    return /* Set11 */3;
  }
  if (num >= 120) {
    return /* Set12 */4;
  }
  switch (num) {
    case 90 :
        return /* Set9 */0;
    case 95 :
        return /* Set95 */1;
    case 91 :
    case 92 :
    case 93 :
    case 94 :
    case 96 :
    case 97 :
    case 98 :
    case 99 :
        return /* Set11 */3;
    case 100 :
        return /* Set10 */2;
    
  }
}

function use(param) {
  var s = React.useContext(ctx);
  return num(s);
}

function useT(param) {
  return React.useContext(ctx);
}

export {
  ctx ,
  Provider ,
  num ,
  num2 ,
  fromNum ,
  use ,
  useT ,
  
}
/* ctx Not a pure module */
