import { useState, memo, forwardRef } from "react";
import { use as useSet } from "common/src/data/TftSet.bs";
import {
  usePath as useBasePath,
  usePath4 as useBasePath4,
} from "common/src/atoms/CdnAtom.bs";
import {
  augments as augments9,
  legendAugments as legendAugments9,
} from "common/src/data/Data9.bs";
import { augments as augments95 } from "common/src/data/Data95.bs";
import { augments as augments10 } from "common/src/data/Data10.bs";
import { augments as augments36 } from "common/src/data/Data36.bs";
import { augments as augments11 } from "common/src/data/Data11.bs";
import { augments as augments12 } from "common/src/data/Data12.bs";
import { augments as augments13 } from "common/src/data/Data13.bs";
import { augments as augments14 } from "common/src/data/Data14.bs";
import { augments as augments56 } from "common/src/data/Data56.bs";
import { augments as augments46 } from "common/src/data/Data46.bs";
import { getSrcSet, getSrcSet2 } from "./ImgCore";
import { make as AugmentTooltip } from "./AugmentTooltip.bs";
import UnitImage from "./UnitImage";
import { useT2 } from "../bindings/Translation.bs";

const augData = (augId: string, set: number): any => {
  switch (set) {
    case 90:
      return augments9[augId] || legendAugments9[augId];
    case 95:
      return augments95[augId];
    case 10:
    case 100:
      return augments10[augId];
    case 36:
      return augments36[augId];
    case 110:
      return augments11[augId];
    case 120:
      return augments12[augId];
    case 130:
      return augments13[augId];
    case 140:
      return augments14[augId];
    case 56:
      return augments56[augId];
    case 46:
      return augments46[augId];
    default:
      return null;
  }
};

const augRank = (augId: string, set: number): number => {
  switch (set) {
    case 90:
      return augments9[augId]?.rank || legendAugments9[augId]?.rank || 1;
    case 95:
      return augments95[augId]?.rank || 1;
    case 10:
    case 100:
      return augments10[augId]?.rank || 1;
    case 36:
      return augments36[augId]?.rank || 1;
    case 110:
      return augments11[augId]?.rank || 1;
    case 120:
      return augments12[augId]?.rank || 1;
    case 130:
      return augments13[augId]?.rank || 1;
    case 140:
      return augments14[augId]?.rank || 1;
    case 56:
      return augments56[augId]?.rank || 1;
    case 46:
      return augments46[augId]?.rank || 1;
    default:
      return 1;
  }
};

type Props = {
  augId: string;
  size: number;
  sizeSm?: number;
  sizeMd?: number;
  sizeLg?: number;
  className?: string;
  rank?: number;
};

const getSrc = (
  basePath: string,
  newPath: boolean,
  usePlaceholder: boolean,
  augId: string,
  rank: number,
) => {
  return `${basePath}/augments${newPath ? "" : "2"}/${
    usePlaceholder
      ? "_"
      : newPath
        ? augId.replace(/TFT(.*?)_Augment_(.+)/, "$1$2")
        : augId
  }${newPath ? "" : "_"}${rank}.png`;
};

const AugmentImage = memo(
  forwardRef(
    (
      {
        augId,
        size,
        sizeSm,
        sizeMd,
        sizeLg,
        className = "",
        rank: baseRank,
        ...rest
      }: Props,
      ref,
    ) => {
      const augmentsT = useT2("augments");
      const set = useSet();
      const basePath = useBasePath();
      const basePath4 = useBasePath4();
      const [usePlaceholder, setUsePlaceholder] = useState(false);

      // const rank = ;
      const rank = baseRank || augRank(augId, set);
      const data = augData(augId, set);

      const newPath = Number.isNaN(Number(augId));

      let src = getSrc(
        newPath ? basePath4 : basePath,
        newPath,
        usePlaceholder,
        augId,
        rank,
      );

      const [sizes, srcSet, sizeClass] = newPath
        ? getSrcSet2(src, size, sizeSm, sizeMd, sizeLg)
        : getSrcSet(src, size, sizeSm, sizeMd, sizeLg);

      return (
        <AugmentTooltip id={augId}>
          {data?.hero ? (
            <div
              className={`relative flex items-center justify-center flex-shrink-0 aspect-square ${sizeClass}`}
            >
              <UnitImage
                unitId={data.hero[0]}
                size={size - 2}
                sizeSm={sizeSm && sizeSm - 2}
                sizeMd={sizeMd && sizeMd - 2}
                sizeLg={sizeLg && sizeLg - 2}
              />
              <div
                className={
                  (data.hero[1] === "carry"
                    ? "carry-aug-marker"
                    : "support-aug-marker") + (size > 32 ? "-lg" : "")
                }
              />
            </div>
          ) : (
            <img
              loading="lazy"
              decoding="async"
              alt={augmentsT(String(augId))}
              src={src + `?${newPath ? "w" : "width"}=${size}`}
              className={`aspect-square ${className} ${sizeClass}`}
              // @ts-ignore
              ref={ref}
              sizes={sizes}
              srcSet={srcSet}
              onError={(_) => setUsePlaceholder(true)}
              {...rest}
            />
          )}
        </AugmentTooltip>
      );
    },
  ),
);

export default AugmentImage;
